import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import Img from "gatsby-image"

import { LayoutQuoting, Title, SEO, SectionCenter, GoBack } from "../components"
import { getCurrentPath } from "../redux/actions"
import purchaseFlows from "../utils/insurerPurchaseFlows"

const GetQuoteDetails = ({ location, getCurrentPath, selectedPlan }) => {
  const [isValidPlan, setIsValidPlan] = useState(false)

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  }, [getCurrentPath, location.pathname, selectedPlan])

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - purchase" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title="some important information to confirm first"
              fontColor="var(--clr-primary-300)"
            />
            <div
              css={css`
                width: 150px;
                margin: -2rem auto 2rem;
              `}
            >
              <Img
                fluid={selectedPlan.logo}
                css={css`
                  vertical-align: middle;
                  width: 100%;
                  height: 100%;
                `}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <SectionCenter>
              {purchaseFlows[selectedPlan.insurer]()}
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut="plan"
            text="Looks like you have not selected a "
            actionText="go back to select one."
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan }) => ({
  selectedPlan,
})

export default connect(mapStateToProps, { getCurrentPath })(GetQuoteDetails)
